<template>
  <div class="modal-wrapper">
    <div class="close" @click="onClose" />
    <ProgressBar :step="step" :states="states" />
    <div id="pdf-wrapper" class="pdf-wrapper" />
    <div class="fixed-bottom" @click="onClose">
      <Button name="關閉預覽"></Button>
    </div>
    <loading :active.sync="pdf5IsNull" :can-cancel="false" :is-full-page="true" :opacity="0.8">
      <div class="loading-text" slot="after">正在讀取檔案</div>
    </loading>
  </div>
</template>

<script>
import Pdfh5 from 'pdfh5';
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import ProgressBar from '@/components/ProgressBar.vue';
import Button from '@/elements/Button.vue';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'PreviewContract',
  props: ['url', 'onClose'],
  components: { ProgressBar, Button, Loading },
  data() {
    return {
      step: 0,
      states: ['合約預覽'],
      pdfh5: null,
    };
  },
  computed: {
    pdf5IsNull() {
      return this.pdfh5 === null;
    },
  },
  mounted() {
    axios
      .get(this.url, {
        responseType: 'arraybuffer',
      })
      .then((res) => {
        this.pdfh5 = new Pdfh5('#pdf-wrapper', {
          data: res.data,
        });
      });
  },
};
</script>
<style>
.modal-wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 3;
}

.loadingWrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pdf-wrapper {
  flex: 1;
  margin-bottom: 60px;
}

.close {
  position: absolute;
  right: 15px;
  top: 10px;
  width: 25px;
  height: 25px;
  opacity: 0.8;
  z-index: 999;
}

.close:before,
.close:after {
  position: absolute;
  left: 12px;
  content: ' ';
  height: 26px;
  width: 2px;
  background-color: #333;
}

.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

.viewerContainer {
  height: calc(100vh - 130px);
}
</style>
